const firebaseConfig = {
  apiKey: "AIzaSyBUqZ8JRHeidevtbDUwlwyw3Gww3O703_s",
  authDomain: "kinamo-3ec0e.firebaseapp.com",
  projectId: "kinamo-3ec0e",
  storageBucket: "kinamo-3ec0e.appspot.com",
  messagingSenderId: "830223469999",
  appId: "1:830223469999:web:6f232d85befa495d6e335a",
  databaseURL: "",
  measurementId: "G-1YEXG8KFZF",
};

export default firebaseConfig;
